// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as City from "../../../models/City.res.js";
import * as Rest from "../../Rest.res.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(cityId, query, providerIds, radius) {
  var query$1 = PreloadedSearchLocation.Index.query(query);
  var providers = PreloadedSearchLocation.Index.providers(providerIds);
  var radius$1 = PreloadedSearchLocation.Index.radius(radius);
  return Rest.$$fetch("/locations/cities/" + ID.toString(cityId) + "/map?" + query$1 + providers + radius$1, "Get", {
              NAME: "Json",
              VAL: City.Show.$$Map.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
