// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(pageNum, sortBy, query, providerIds, cityIdOpt, radius) {
  var cityId = cityIdOpt !== undefined ? Caml_option.valFromOption(cityIdOpt) : undefined;
  var query$1 = PreloadedSearchLocation.Index.query(query);
  var page = PreloadedSearchLocation.Index.page(pageNum);
  var providers = PreloadedSearchLocation.Index.providers(providerIds);
  var sortBy$1 = PreloadedSearchLocation.Index.sortBy(sortBy);
  var cityId$1 = PreloadedSearchLocation.Index.cityId(cityId);
  var radius$1 = PreloadedSearchLocation.Index.radius(radius);
  return Rest.$$fetch("/locations?" + query$1 + page + providers + sortBy$1 + radius$1 + cityId$1, "Get", {
              NAME: "Json",
              VAL: PreloadedSearchLocation.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
