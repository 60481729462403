// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as State from "../../../models/State.res.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(stateId, query, providerIds, cityId, radius) {
  var query$1 = PreloadedSearchLocation.Index.query(query);
  var providers = PreloadedSearchLocation.Index.providers(providerIds);
  var cityId$1 = PreloadedSearchLocation.Index.cityId(cityId);
  var radius$1 = PreloadedSearchLocation.Index.radius(radius);
  return Rest.$$fetch("/locations/states/" + ID.toString(stateId) + "/map?" + query$1 + providers + cityId$1 + radius$1, "Get", {
              NAME: "Json",
              VAL: State.Show.$$Map.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
