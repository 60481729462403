// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(query, providerIds, cityId, radius) {
  var providers = PreloadedSearchLocation.Index.providers(providerIds);
  var query$1 = PreloadedSearchLocation.Index.query(query);
  var cityId$1 = PreloadedSearchLocation.Index.cityId(cityId);
  var radius$1 = PreloadedSearchLocation.Index.radius(radius);
  return Rest.$$fetch("/locations/map?" + providers + query$1 + cityId$1 + radius$1, "Get", {
              NAME: "Json",
              VAL: PreloadedSearchLocation.Index.$$Map.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
